<header *ngIf="config.title !== 'Murgasen' && config.title !== 'Aiema' && config.title !== 'BombayHardware' && config.title !== 'AvonSeals' && config.title !== 'Haven' && config.title !== 'Beaubelle' && config.title !== 'Mayan' ">
  <div  class="main-navbar">
    <div id="mainnav">
      <div class="toggle-nav" (click)="navMainMenuToggle()">
        <i class="fa fa-bars sidebar-bar"></i>
      </div>
      <ul class="nav-menu" [class.opennav]="mainMenuToggle" style="font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;">
        <li class="back-btn">
          <div class="mobile-back text-right" (click)="navMainMenuToggle()">
            <i class="fa fa-angle-left pl-2" aria-hidden="true"></i> &nbsp;
            <span>Back</span>

          </div>
        </li>

        <li [ngClass]="{'close-submenu': menuItem.active === true}" [class]="menuItem.type"
          *ngFor="let menuItem of allMenuItem" [class.mega-menu]="menuItem.megaMenu">
          <!-- Child -->
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="menuItem.children" (click)="showSubmenu()"> 
            {{ menuItem.title | titlecase}}
            <span class="fa fa-angle-down" style="font-size:16px; font-weight:600"></span>
          </a>
          <!-- noChild -->
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="!menuItem.children && menuItem.type==='sub'" 
          (click)="toggletMainActive(menuItem)" (click)="navMainMenuToggle()">
            {{ menuItem.title | titlecase}}
          </a>
          <!-- Link -->
          <a href="javascript:void(0)" (click)="openLink(menuItem.link, menuItem.param)" (click)="navMainMenuToggle()" class="level-1 nav-link" *ngIf="menuItem.type === 'link'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'extLink'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'extTabLink'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'nosub'">
            {{ menuItem.title | titlecase}}

          </a>

          <!-- 2nd Level Menu -->
          <ul class="nav-submenu" id="nav-showmenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
            <li [ngClass]="{'close-submenu': childrenItem.active === true}"
              *ngFor="let childrenItem of menuItem.children">
              <!-- Sub -->
              <a href="javascript:void(0)" (click)="toggletNavActive(childrenItem,menuItem.link)" (click)="navMainMenuToggle()" style="text-transform:uppercase;font-family: 'Inter-SemiBold';">
                {{ childrenItem.name | titlecase}}
                <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.name}}</span>
                <span class="sub-arrow" *ngIf="childrenItem.children"></span>
              </a>

            </li>
          </ul>


  </li>
  </ul>
  </div>
  </div>
</header>

 <header   *ngIf="config.title ==='Beaubelle'"  >
  <div  class="main-navbar">
    <div id="mainnav">
      <div class="toggle-nav" (click)="navMainMenuToggle()">
        <i class="fa fa-bars sidebar-bar"></i>
      </div>
      </div> 
      <ul class="nav-menu" [class.opennav]="mainMenuToggle" style="font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;">
   
        <li class="back-btn">
          <div class="mobile-back text-right" (click)="navMainMenuToggle()">
            <i class="fa fa-angle-left pl-2" aria-hidden="true"></i> &nbsp;
            <span>Back</span>

          </div>
        </li>
        <li [ngClass]="{'close-submenu': menuItem.active === true}" [class]="menuItem.type"
          *ngFor="let menuItem of allMenuItem" [class.mega-menu]="menuItem.megaMenu">
         
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="menuItem.type === 'sub'"
            (click)="toggletMainActive(menuItem)">
            {{ menuItem.title | titlecase}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
         
          <a class="nav-link level-1" *ngIf="menuItem.type === 'noChild'"
            (click)="nosubCategory(menuItem.link)">
            {{ menuItem.title | titlecase}}

          </a>
         
          <a href="javascript:void(0)" (click)="openLink(menuItem.link, menuItem.param)" class="level-1 nav-link" *ngIf="menuItem.type === 'link'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
         


  </li>
  </ul>
  </div>
 
</header> 
<header   *ngIf="config.title ==='Mayan'"  >
  <div  class="main-navbar">
    <div id="mainnav">
      <div class="toggle-nav" (click)="navMainMenuToggle()">
        <i class="fa fa-bars sidebar-bar"></i>
      </div>
      </div> 
      <ul class="nav-menu" [class.opennav]="mainMenuToggle" style="font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;">
   
        <li class="back-btn">
          <div class="mobile-back text-right" (click)="navMainMenuToggle()">
            <i class="fa fa-angle-left pl-2" aria-hidden="true"></i> &nbsp;
            <span>Back</span>

          </div>
        </li>
        <li [ngClass]="{'close-submenu': menuItem.active === true}" [class]="menuItem.type"
          *ngFor="let menuItem of allMenuItem" [class.mega-menu]="menuItem.megaMenu">
         
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="menuItem.type === 'sub'"
            (click)="toggletMainActive(menuItem)" >
            {{ menuItem.title | titlecase}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
         
          <a class="nav-link level-1" *ngIf="menuItem.type === 'noChild'"
            (click)="nosubCategory(menuItem.link)">
            {{ menuItem.title | titlecase}}

          </a>
         
          <a href="javascript:void(0)" (click)="openLink(menuItem.link, menuItem.param)" class="level-1 nav-link" *ngIf="menuItem.type === 'link'" style="color: #3c707c!important;padding: 14px 46px!important;font-size: 14px!important;">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
         


  </li>
  </ul>
  </div>
 
</header> 


<!-- <header *ngIf="config.title ==='Bestgifts'">

 
</header>  -->





<header *ngIf="config.title === 'Haven'">
  <!-- <div  class="main-navbar">
    <div id="mainnav">
      <div class="toggle-nav" (click)="navMainMenuToggle()">
        <i class="fa fa-bars sidebar-bar"></i>
      </div>
      <ul class="nav-menu" [class.opennav]="mainMenuToggle" style="font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;">
        <li class="back-btn">
          <div class="mobile-back text-right" (click)="navMainMenuToggle()">
            <i class="fa fa-angle-left pl-2" aria-hidden="true"></i> &nbsp;
            <span>Back</span>

          </div>
        </li>

        <li [ngClass]="{'close-submenu': menuItem.active === true}" [class]="menuItem.type"
          *ngFor="let menuItem of allMenuItem" [class.mega-menu]="menuItem.megaMenu">
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="menuItem.type === 'sub'"
            (click)="toggletMainActive(menuItem)">
            {{ menuItem.title | titlecase}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          
          <a class="nav-link level-1" *ngIf="menuItem.type === 'noChild'"
            (click)="nosubCategory(menuItem.link)">
            {{ menuItem.title | titlecase}}

          </a>
          
          <a href="javascript:void(0)" (click)="openLink(menuItem.link, menuItem.param)" class="level-1 nav-link" *ngIf="menuItem.type === 'link'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
         
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'extLink'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'extTabLink'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
      
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'nosub'">
            {{ menuItem.title | titlecase}}

          </a>

          
          <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
            <li [ngClass]="{'close-submenu': childrenItem.active === true}"
              *ngFor="let childrenItem of menuItem.children">
             
              <a href="javascript:void(0)" (click)="toggletNavActive(childrenItem)" style="text-transform:uppercase;font-family: 'Inter-SemiBold';">
                {{ childrenItem.name | titlecase}}
                <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.name}}</span>
                <span class="sub-arrow" *ngIf="childrenItem.children"></span>
              </a>

            </li>
          </ul>


  </li>
  </ul>
  </div>
  </div> -->
  <div id="desktopMenu" class="container">
  <div class="inq-menu-st row justify-content-center">
    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
            class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
        <div id="navbarNav" class="collapse navbar-collapse">
            <ul class="navbar-nav">
                <li class="nav-item" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                        href="#/">HOME</a></li>
                <li class="nav-item" id="menus"><a class="nav-link menu-text" ng-reflect-router-link="product"
                        href="#/product">MENU</a></li>
                <li class="nav-item" id="tableBooking"><a class="nav-link menu-text" ng-reflect-router-link="table-booking"
                  href="#/table-booking">TABLE BOOKING</a></li>
               <!-- <li class="nav-item" id="giftexperience"><a class="nav-link menu-text" ng-reflect-router-link="gift-meal"
                href="#/gift-meal"    >GIFT AN EXPERIENCE</a></li> -->
                <li class="nav-item" id="giftAMeal"><a class="nav-link menu-text" ng-reflect-router-link="gift-meal"
                  href="#/gift-meal">GIFT AN EXPERIENCE</a></li>
                <li class="nav-item" id="about"><a class="nav-link menu-text" ng-reflect-router-link="about-us"
                        href="#/about-us">ABOUT US</a></li>
                <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                        href="#/contact-us">CONTACT US</a></li>
                        
            </ul>
        </div>
    </nav>
</div>
</div>
</header>

<header *ngIf="config.title === 'BombayHardware'">
  <div  class="main-navbar">
    <div id="mainnav">
      <div class="toggle-nav" (click)="navMainMenuToggle()">
        <i class="fa fa-bars sidebar-bar"></i>
      </div>
      <ul class="nav-menu" [class.opennav]="mainMenuToggle" style="font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;">
        <li class="back-btn">
          <div class="mobile-back text-right" (click)="navMainMenuToggle()">
            <i class="fa fa-angle-left pl-2" aria-hidden="true"></i> &nbsp;
            <span>Back</span>

          </div>
        </li>

        <li [ngClass]="{'close-submenu': menuItem.active === true}" [class]="menuItem.type"
          *ngFor="let menuItem of allMenuItem" [class.mega-menu]="menuItem.megaMenu">
          <!-- Sub -->
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="menuItem.type === 'sub'"
            (click)="toggletMainActive(menuItem)" (click)="navMainMenuToggle()"(click)="navMainMenuToggle()" style="color: #AD342A!important">
            {{ menuItem.title | titlecase}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- noChild -->
          <a class="nav-link level-1" *ngIf="menuItem.type === 'noChild'"
            (click)="nosubCategory(menuItem.link)" (click)="navMainMenuToggle()" style="color: #AD342A!important">
            {{ menuItem.title | titlecase}}
          </a>
          <!-- Link -->
          <a href="javascript:void(0)" (click)="openLink(menuItem.link, menuItem.param)" (click)="navMainMenuToggle()" style="color: #AD342A!important" class="level-1 nav-link" *ngIf="menuItem.type === 'link'">
            {{ menuItem.title | titlecase}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" style="color: #AD342A!important" *ngIf="menuItem.type === 'extLink'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" style="color: #AD342A!important" *ngIf="menuItem.type === 'extTabLink'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" style="color: #AD342A!important" *ngIf="menuItem.type === 'nosub'">
            {{ menuItem.title | titlecase}}

          </a>

          <!-- 2nd Level Menu -->
          <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
            <li [ngClass]="{'close-submenu': childrenItem.active === true}"
              *ngFor="let childrenItem of menuItem.children">
              <!-- Sub -->
              <a href="javascript:void(0)" (click)="toggletNavActive(childrenItem,menuItem.link)" style="text-transform:uppercase;font-family: 'Inter-SemiBold';color: #AD342A!important">
                {{ childrenItem.name | titlecase}}
                <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.name}}</span>
                <span class="sub-arrow" *ngIf="childrenItem.children"></span>
              </a>

            </li>
          </ul>


  </li>
  </ul>
  </div>
  </div>
</header>
<!-- <header *ngIf="config.title === 'Beaubelle'">
  <div class="flex-container" style="justify-content: center;"  *ngIf="config.title === 'Beaubelle'"> 
    <div class="inq-menu-st row justify-content-center">
        <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
            <div id="navbarNav" class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                            href="#/">HOME</a></li>
                    <li class="header-cart account-popup1" id="shop">
                        <a href="javascript:;" class="nav-link menu-text">PRODUCTs</a>
                       
                        </li>
                        <li class="header-cart account-popup1" id="shop">
                          <a (click)="navigateToBeaubelle_category()" class="nav-link menu-text">BEAUBELLE</a>
 
                          </li><li class="header-cart account-popup1" id="shop">
                            <a (click)="navigateToBB_category()" class="nav-link menu-text">B&B</a>
                           
                            </li><li class="header-cart account-popup1" id="shop">
                              <a  (click)="navigateToMCcosmetics_category()" class="nav-link menu-text">MC COSMETICS</a>
                             
                              </li>
                    <li class="nav-item" id="about"><a class="nav-link menu-text" ng-reflect-router-link="about-us"
                            href="#/about-us">ABOUT US</a></li>
                    <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                            href="#/contact-us">CONTACT US</a></li>
                            
                </ul>
            </div>
        </nav>
    </div>
    </div>
</header> -->






<header>
  <div  class="main-navbar">
    <div id="mainnav" *ngIf="config.title === 'Aiema'">
      <div class="toggle-nav" (click)="navMainMenuToggle()">
        <i class="fa fa-bars sidebar-bar"></i>
      </div>
      <!-- <ul class="nav-menu" [class.opennav]="mainMenuToggle" style="font-family: 'proxima-regular','sans-serif'!important; font-weight:600 !important;color:#D57C30!important;"> -->
        <ul class="nav-menu" [class.opennav]="mainMenuToggle" >

        <li class="back-btn">
          <div class="mobile-back text-right" (click)="navMainMenuToggle()">
            <i class="fa fa-angle-left pl-2" aria-hidden="true"></i> &nbsp;
            <span>Back</span>

          </div>
        </li>

        <li [ngClass]="{'close-submenu': menuItem.active === true}" [class]="menuItem.type"
          *ngFor="let menuItem of allMenuItem" [class.mega-menu]="menuItem.megaMenu">
          <!-- Sub -->
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="menuItem.type === 'sub'"
            (click)="toggletMainActive(menuItem)" (click)="navMainMenuToggle()" style="font-family: 'proxima-regular','sans-serif'!important; font-weight:600 !important;color:#D57C30!important;font-size: 18px!important;">
            {{ menuItem.title | titlecase}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- noChild -->
          <a class="nav-link level-1" *ngIf="menuItem.type === 'noChild'"
            (click)="nosubCategory(menuItem.link)" (click)="navMainMenuToggle()" style="font-family: 'proxima-regular','sans-serif'!important; font-weight:600 !important;color:#D57C30!important;font-size: 18px!important;">
            {{ menuItem.title | titlecase}}

          </a>
          <!-- Link -->
          <a href="javascript:void(0)" (click)="openLink(menuItem.link, menuItem.param)" (click)="navMainMenuToggle()" class="level-1 nav-link" *ngIf="menuItem.type === 'link'" style="font-family: 'proxima-regular','sans-serif'!important; font-weight:600 !important;color:#D57C30!important;font-size: 18px!important;">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'extLink'" style="font-family: 'proxima-regular','sans-serif'!important; font-weight:600 !important;color:#D57C30!important;font-size: 18px!important;">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'extTabLink'" style="font-family: 'proxima-regular','sans-serif'!important; font-weight:600 !important;color:#D57C30!important;font-size: 18px!important;">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
           <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'nosub'" style="font-family: 'proxima-regular','sans-serif'!important; font-weight:600 !important;color:#D57C30!important;font-size: 18px!important;">
            {{ menuItem.title | titlecase}}

          </a>

          <!-- 2nd Level Menu -->
          <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children" style="font-family: 'proxima-regular','sans-serif'!important; font-weight:600 !important;color:#D57C30!important;font-size: 18px!important;">
            <li [ngClass]="{'close-submenu': childrenItem.active === true}"
              *ngFor="let childrenItem of menuItem.children">
              <!-- Sub -->
              <a href="javascript:void(0)" (click)="toggletNavActive(childrenItem,menuItem.link)" style="font-family: 'proxima-regular','sans-serif'!important; font-weight:600 !important;color:#D57C30!important;font-size: 18px!important;">
                {{ childrenItem.name | titlecase}}
                <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.name}}</span>
                <span class="sub-arrow" *ngIf="childrenItem.children"></span>
              </a>

            </li>
          </ul>


  </li>
  </ul>
  </div>
  </div>
</header>

<header id="murugesan">
  <div class="main-navbar" *ngIf="config.title === 'Murgasen'">
    <div id="mainnav">
      <div class="toggle-nav" (click)="navMainMenuToggle()">
        <i class="fa fa-bars sidebar-bar"></i>
      </div>
      <ul class="nav-menu" [class.opennav]="mainMenuToggle">

        <li class="back-btn">
          <div class="mobile-back text-right" (click)="navMainMenuToggle()">
            <i class="fa fa-angle-left pl-2" aria-hidden="true"></i> &nbsp;
            <span>Back</span>

          </div>
        </li>

        <li [ngClass]="{'close-submenu': menuItem.active === true}" [class]="menuItem.type"
          *ngFor="let menuItem of allMenuItem" [class.mega-menu]="menuItem.megaMenu">
          <!-- Sub -->
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="menuItem.type === 'sub'"
            (click)="toggletNavActive(menuItem,menuItem.link)">
            {{ menuItem.title  | titlecase}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- noChild -->
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="menuItem.type === 'noChild'"
            (click)="nosubCategory(menuItem.path)">
            {{ menuItem.title  | titlecase}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- Link -->
          <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="level-1 nav-link"
            *ngIf="menuItem.type === 'link'">
            {{ menuItem.title  | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'extLink'">
            {{ menuItem.title  | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'extTabLink'">
            {{ menuItem.title  | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'nosub'">
            {{ menuItem.title  | titlecase}}

          </a>

          <!-- 2nd Level Menu -->
          <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
            <li [ngClass]="{'close-submenu': childrenItem.active === true}"
              *ngFor="let childrenItem of menuItem.children">
              <!-- Sub -->
              <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" style="cursor: pointer;" (click)="toggletNavActive(childrenItem,menuItem.link)">
                {{ childrenItem.title  | titlecase}}
                <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText}}</span>
                <span class="sub-arrow" *ngIf="childrenItem.children"></span>
              </a>
              <!-- Link -->
              <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                *ngIf="childrenItem.type === 'link' " (click)="submenuFn(menuItem.title, childrenItem.title)">
                {{ childrenItem.title  | titlecase}}
                <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText }}</span>
                <span class="sub-arrow" *ngIf="childrenItem.children"></span>
              </a>
              <!-- External Link -->
              <a href="{{ childrenItem.path }}" *ngIf="childrenItem.type === 'extLink' ">
                {{ childrenItem.title  | titlecase}}
                <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText }}</span>
                <span class="sub-arrow" *ngIf="childrenItem.children"></span>
              </a>
              <!-- External Tab Link -->
              <a href="{{ childrenItem.path }}" target="_blank" *ngIf="childrenItem.type === 'extTabLink' ">
                {{ childrenItem.title  | titlecase}}
                <!-- <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText }}</span> -->
                <span class="sub-arrow" *ngIf="childrenItem.children"></span>
              </a>

              <!-- 3rd Level Menu -->
              <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
                <li [ngClass]="{'close-submenu': childrenSubItem.active === true}"
                  *ngFor="let childrenSubItem of childrenItem.children">
                  <!-- Sub -->
                  <a href="javascript:void(0)" *ngIf="childrenSubItem.type === 'sub'"
                    (click)="toggletNavActive(childrenSubItem,menuItem.link)">
                    {{ childrenSubItem.title  | titlecase}}
                    <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText}}</span>
                    <span class="sub-arrow" *ngIf="childrenSubItem.children"></span>
                  </a>
                  <!-- Link -->
                  <a [routerLink]="childrenSubItem.path" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type === 'link' "
                    (click)="submenuFn(menuItem.title, childrenSubItem.title)">
                    {{ childrenSubItem.title  | titlecase}}
                    <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText}}</span>
                    <span class="sub-arrow" *ngIf="childrenSubItem.children"
                      (click)="toggletNavActive(childrenSubItem,menuItem.link)"></span>
                  </a>
                  <!-- External Link -->
                  <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink' ">
                    {{ childrenSubItem.title  | titlecase}}
                    <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText }}</span>
                    <span class="sub-arrow" *ngIf="childrenSubItem.children"></span>
                  </a>
                  <!-- External Tab Link -->
                  <a href="{{ childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink' ">
                    {{ childrenSubItem.title  | titlecase}}
                    <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText}}</span>
                    <span class="sub-arrow" *ngIf="childrenSubItem.children"></span>
                  </a>
                  <!-- 4th Level Menu -->
                  <ul *ngIf="childrenSubItem.children" [class.opensubchild]="childrenSubItem.active"
                    class="nav-sub-childmenu">
                    <li [ngClass]="{'close-submenu': childrenSubSubItem.active === true}"
                      *ngFor="let childrenSubSubItem of childrenSubItem.children">
                      <!-- Link -->
                      <a [routerLink]="childrenSubSubItem.path" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubSubItem.type === 'subChild' "
                        (click)="submenuFn(menuItem.title, childrenSubSubItem.title)">
                        {{ childrenSubSubItem.title  | titlecase}}
                        <span class="new-tag" *ngIf="childrenSubSubItem.badge">{{ childrenSubSubItem.badgeText}}</span>
                      </a>
                      <!-- External Link -->
                      <a href="{{ childrenSubSubItem.path }}" *ngIf="childrenSubSubItem.type === 'extLink' ">
                        {{ childrenSubSubItem.title  | titlecase}}
                        <span class="new-tag" *ngIf="childrenSubSubItem.badge">{{ childrenSubSubItem.badgeText }}</span>
                      </a>
                      <!-- External Tab Link -->
                      <a href="{{ childrenSubSubItem.path }}" target="_blank"
                        *ngIf="childrenSubSubItem.type === 'extTabLink' ">
                        {{ childrenSubSubItem.title  | titlecase}}
                        <span class="new-tag" *ngIf="childrenSubSubItem.badge">{{ childrenSubSubItem.badgeText}}</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">
            <div class="container">
              <div class="row">
                <div class="col mega-box" *ngFor="let childrenItem of menuItem.children; let i=index">
                  <div class="link-section" *ngIf='i<=5'>
                    <div class="menu-title">
                      <h5 *ngIf="childrenItem.title!=='MISCELLANEOUS'"
                        (click)="categoryListing(childrenItem.title)" style="cursor: pointer;">
                        {{ childrenItem.title | titlecase }}
                        <!-- <span class="sub-arrow" *ngIf="childrenItem.children"></span> -->
                      </h5>
                    </div>
                    <div class="menu-content opensubmegamenu">
                      <ul *ngIf="childrenItem.children.length>0">
                        <li *ngFor="let childrenSubItem of childrenItem.children">
                          <!-- Sub -->
                          <a (click)="categoryListing(childrenSubItem.title)" *ngIf="childrenSubItem.type === 'sub'">
                            {{ childrenSubItem.title| titlecase }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText }}</span>
                          </a>
                          <!-- Link -->

                          <div class="wrapper">
                            <div class="wrapper" *ngIf="childrenItem.title==='MISCELLANEOUS'">
                              <a [routerLink]="childrenSubItem.path" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type === 'link' "
                                (click)="submenuFn(menuItem.title, childrenSubItem.title)" class="wrapper">
                                {{ childrenSubItem.title | titlecase }}
                                <span class="sub-arrow" *ngIf="childrenSubItem.children"></span>
                                <span class="new-tag" *ngIf="childrenSubItem.badge">{{
                                  childrenSubItem.badgeText}}</span>
                              </a>
                            </div>
                            <div class="wrapper" *ngIf="childrenItem.title!=='MISCELLANEOUS' && !childrenSubItem.children">
                              <a [routerLink]="childrenSubItem.path" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type === 'link' "
                                (click)="categoryListing(childrenSubItem.title)" class="wrapper">
                                {{ childrenSubItem.title | titlecase }}
                              </a>
                            </div>
                            <div class="menu-title wrapper" *ngIf="childrenItem.title!=='MISCELLANEOUS' && childrenSubItem.children">
                              <a [routerLink]="childrenSubItem.path" routerLinkActive="active"
                                style="font-weight:900; color: rgb(24, 23, 23);"
                                [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type === 'link' "
                                (click)="submenuFn(menuItem.title, childrenSubItem.title)" class="wrapper">
                                {{ childrenSubItem.title | titlecase }}
                                <span class="sub-arrow" *ngIf="childrenSubItem.children"></span>
                                <span class="new-tag" *ngIf="childrenSubItem.badge">{{
                                  childrenSubItem.badgeText}}</span>
                              </a>
                            </div>
                            <div class="wrapper">
                              <div class="wrapper">
                                <li *ngFor="let childrenSubSubItem of childrenSubItem.children" class="wrapper">
                                  <a [routerLink]="childrenSubSubItem.path" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    *ngIf="childrenSubSubItem.type === 'subChild' "
                                    (click)="submenuFn(menuItem.title, childrenSubSubItem.title)">
                                    {{ childrenSubSubItem.title | titlecase }}
                                    <span class="new-tag" *ngIf="childrenSubSubItem.badge">{{
                                      childrenSubSubItem.badgeText}}</span>
                                  </a>
                                  <div class="wrapper">
                                    <li *ngFor="let childrenNextSubItem of childrenSubSubItem.children" class="wrapper">
                                      <a [routerLink]="childrenNextSubItem.path" routerLinkActive="active"
                                        style="font-weight:bold; color: rgb(24, 23, 23);" [routerLinkActiveOptions]="{exact: true}"
                                        *ngIf="childrenNextSubItem.type === 'nextSubChild' "
                                        (click)="submenuFn(menuItem.title, childrenNextSubItem.title)">
                                        {{ childrenNextSubItem.title | titlecase }}
                                        <span class="new-tag" *ngIf="childrenNextSubItem.badge">{{
                                          childrenNextSubItem.badgeText}}</span>
                                      </a>
                                    </li>
                                  </div>
                                </li>

                              </div>
                            </div>
                </div>


                <!-- External Link -->
                <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink' ">
                  {{ childrenSubItem.title  | titlecase}}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText }}</span>
                </a>
                <!-- External Tab Link -->
                <a href="{{ childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink' ">
                  {{ childrenSubItem.title  | titlecase}}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText }}</span>
                </a>
        </li>
      </ul>
    </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div>
</header>
