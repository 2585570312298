import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FooterTwoComponent } from "./footer-two.component";


@NgModule ({

    declarations: [
        FooterTwoComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [
        FooterTwoComponent
    ]

})

export class FooterTwoModule { };