import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { HeaderService } from '../../header/header-two/header.service';
import metaData from 'src/assets/data/default/header-footer.json';
import { config }  from 'src/configs/config';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-luckme-footer',
  templateUrl: './footer-luckme.component.html'
})
export class FooterLuckmeComponent implements OnInit {
  windowscroll()
  {
    window.scrollTo(0,0)
  }

  copyRightText:any = "";
  config=config;
  socialMediaData:any = [];
  facebookLink;
  instagramLink;
  pinterestLink;
  twitterLink;
  linkedlnLink;
  onLineShopping = metaData.footer;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private localService: LocalStorageService,
    public appService: AppService,
    // private appService : AppService
  ) { }

  ngOnInit(): void {
    // this.getCopyRightText();
    this.getSocialMediaDetails();
  }

  navigateToProduct(link :any){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    if (config.title == 'ShubhCards' || config.title == 'Deera' || config.title == 'BestGifts') {
  
      this.localService.set('is_category_filter','true')
      this.localService.set('category_filter_name',link)
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[link],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });


    }
    else if (config.title === 'Carience') {
      let userId = this.localService.get('UserId');
      if(userId){
      // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[link],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      }
      else{
       this.router.navigate([`/login-new`], {queryParams: { url: this.router.url }, relativeTo:this.route,  skipLocationChange: false });
      }
     }
     else{
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[link],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

     }
   
   // this.router.navigate([`/productListing`], { queryParams: {terms:link, type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
  }
  // productListing(){
	// 	this.router.navigate([`/productListing`], { queryParams: {term:'', type: 'last90days' }, relativeTo: this.route, skipLocationChange: false });

  //   }

  

  getBrochure(){
    //this.headerService.updateAdminFormEvent('clearSearchTerm');
    window.open("http://d1bo46l1lnh354.cloudfront.net/Mahaveer/Brochure/ITR-Lubricants-Brochure.pdf")
  }


  getSocialMediaDetails(){
   
    // REQUEST DATA
    let request_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
          "find": {}
      }
    }
    // MAKE AN API CALL
    this.appService.postApiCall(request_data, ENDPOINTS.STORE_GALLERY).subscribe(  //LINE 
      resp => {
        let respData = resp.result.data[0];
        if(respData.social){
          if(respData.social.media.length > 0){          
            this.socialMediaData = respData.social.media;
          }
          
          this.socialMediaData.forEach(social => {
           if(social.media_name === 'Facebook' && social.media_link !== ''){
              this.facebookLink = social.media_link
           } else if(social.media_name === 'Twitter' && social.media_link !== ''){
            this.twitterLink = social.media_link
           } else if(social.media_name === 'Instagram' && social.media_link !== ''){
            this.instagramLink = social.media_link
           } else if(social.media_name === 'Linkedln' && social.media_link !== ''){
            this.linkedlnLink = social.media_link
           } else if(social.media_name === 'Pinterest' && social.media_link !== ''){
            this.pinterestLink = social.media_link
           }
          
        });
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }

  getLatestProducts(){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    if (config.title === 'Carience') {
      let userId = this.localService.get('UserId');
      if(userId){
      // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      }
      else{
       this.router.navigate([`/login-new`], {queryParams: { url: this.router.url }, relativeTo:this.route,  skipLocationChange: false });
      }
     }
     else{
      this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });

     }
     }

  windowOpen(social){
    window.open(social)
  }

  // getCopyRightText(){
  //   // REQUEST DATA
  //   let request_data = {
  //     "domain_name": "www.mahaveerdistributor.in",
  //     "user_id": 17,
  //     "extras": {
  //         "find": {}
  //     }
  //   }
  //   // MAKE AN API CALL
  //   this.appService.postApiCall(request_data, ENDPOINTS.STORE_SETTINGS).subscribe(
  //     resp => {
  //       let respData = resp.result.data[0];
  //       if(respData.website){
  //         this.copyRightText = respData.website.copyright_text;
  //       }
  //     },
  //     err => {
  //       if (err.error.error.message) {
  //         console.log(err.error.error.message)
  //       }
  //       else{
  //         console.log('Something bad happened; Please try again!')
  //       }
  //     })
  // }
}
